<template>
	<svg
		height="15"
		width="20"
		fill="#805AD5"
		viewBox="0 0 20 15"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.908688 1.34845V0.817069L1.28447 0.441286L2.19158 1.34845H0.908688ZM1.12757 0.15625L0.908688 0.375127V0.15625H1.12757ZM2.63351 1.34845L1.44138 0.15625H3.54288V1.34845H2.63351ZM3.85538 1.34845V0.15625H16.5836V1.34845H3.85538ZM16.8961 1.35474V0.160114C18.4859 0.238937 19.7611 1.51397 19.8399 3.10392H18.6453C18.5699 2.17272 17.8273 1.43007 16.8961 1.35474ZM18.6516 3.41642H19.8438V10.6274H18.6516V3.41642ZM18.6453 10.9399H19.8399C19.7611 12.5297 18.4859 13.8049 16.8961 13.8837V12.6891C17.8273 12.6138 18.5699 11.8711 18.6453 10.9399ZM16.5836 12.6954V13.8876H6.36321V12.6954H16.5836ZM6.05071 12.6891V13.8837C4.46076 13.8049 3.18572 12.5298 3.1069 10.9399H4.30152C4.37685 11.8711 5.1195 12.6138 6.05071 12.6891ZM4.29523 10.6274H3.10304V4.68626H4.29523V10.6274ZM4.29054 4.37376H3.09436C3.06483 4.10889 2.96077 3.85767 2.79435 3.6495L3.64015 2.80371C4.02649 3.23945 4.25557 3.79246 4.29054 4.37376ZM3.42249 2.57942L2.57951 3.4224L0.817962 1.66095H2.50407L3.42249 2.57942ZM0.596188 1.12957V1.43918L0.441377 1.28438L0.596188 1.12957ZM0.596188 0.687627L0.235129 1.04869C0.203234 0.993017 0.180362 0.932264 0.167708 0.86866C0.144702 0.753019 0.156506 0.633153 0.20163 0.524222C0.246753 0.41529 0.323167 0.322187 0.421208 0.256688C0.475133 0.220661 0.534268 0.193876 0.596188 0.177068V0.687627Z"
			fill="#805AD5"
			stroke="#805AD5"
			strokeWidth="0.3125"
		/>
		<path
			d="M6.70856 5.51709H16.2383M6.70856 8.52648H12.2258"
			stroke="#805AD5"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
		/>
	</svg>
</template>

<script>
export default {
	name: 'WMACallIcon',
}
</script>
