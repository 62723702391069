import Service from './Service'
import axios from 'axios'

/**
 * EventLogger API service.
 *
 */
export default class EventLogger extends Service {
	create(payload) {
		return this.request.post('v4/events', payload)
	}

	healthCheck(activePartnerId, refreshIntervalInMinutes) {
		const username = process.env.VUE_APP_HEALTH_CHECK_AUTH_USERNAME
		const password = process.env.VUE_APP_HEALTH_CHECK_AUTH_PASSWORD

		const encodedCredentials = Buffer.from(
			`${username}:${password}`
		).toString('base64')

		return axios.get(
			`${process.env.VUE_APP_API_URL}/api/v4/partners/${activePartnerId}/calls/published?since=${refreshIntervalInMinutes}m`,
			{
				headers: {
					Authorization: `Basic ${encodedCredentials}`,
				},
			}
		)
	}
}
