<template>
	<div class="dashboard-chart">
		<div class="dashboard-chart-header">
			<div class="dashboard-chart-header-container max-w-lg">
				<h2 class="dashboard-chart-title">
					Daily Call Volume
				</h2>
			</div>
			<div
				class="dashboard-chart-header-container dashboard-chart-response-times max-w-sm"
			>
				<h2 class="dashboard-chart-title m-1">
					Overall Response Times
				</h2>
				<h2 class="insight-chart-subtitle1">
					Urgent calls
				</h2>
				<h2 class="insight-chart-subtitle2">
					Non-Urgent calls
				</h2>
			</div>
			<div class="dashboard-response-time-container mt-6 max-w-none">
				<div class="w-40">
					<div class="dashboard-response-time-title">
						<div class="dashboard-response-time-icon">
							<font-awesome-icon
								:icon="['fal', 'tachometer-slowest']"
							/>
						</div>
						Slowest
					</div>
					<div class="dashboard-response-time-data">
						{{ maxResponseTime }}
					</div>
					<div class="dashboard-response-time-data">
						{{ maxResponseTimeNonUrgent }}
					</div>
				</div>

				<div class="w-40">
					<div class="dashboard-response-time-title">
						<div class="dashboard-response-time-icon">
							<font-awesome-icon
								:icon="['fal', 'tachometer-average']"
							/>
						</div>
						Average
					</div>
					<div class="dashboard-response-time-data">
						{{ averageResponseTime }}
					</div>
					<div class="dashboard-response-time-data">
						{{ averageResponseTimeNonUrgent }}
					</div>
				</div>

				<div class="w-40">
					<div class="dashboard-response-time-title">
						<div class="dashboard-response-time-icon">
							<font-awesome-icon
								:icon="['fal', 'tachometer-fastest']"
							/>
						</div>
						Fastest
					</div>
					<div class="dashboard-response-time-data">
						{{ minResponseTime }}
					</div>
					<div class="dashboard-response-time-data">
						{{ minResponseTimeNonUrgent }}
					</div>
				</div>
			</div>
		</div>
		<apex-chart
			height="400px"
			width="100%"
			:options="options"
			:series="series"
		/>
	</div>
</template>

<script>
import ApexChart from 'vue-apexcharts'

/**
 * The default chart options.
 *
 * @return {Object}
 */
const getDefaultChartOptions = () => {
	return {
		chart: {
			type: 'area',
			foreColor: '#fff',
			stacked: false,
		},
		dataLabels: {
			enabled: false,
		},
		grid: {
			show: false,
		},
		stroke: {
			width: 2,
			curve: 'smooth',
		},
		xaxis: {
			type: 'category',
			categories: [],
			tickAmount: 15,
			offsetY: 5,
			labels: {
				minHeight: 45,
				style: {
					colors: ['white'],
					fontSize: '14px',
					fontWeight: 600,
					fontFamily: 'roboto',
				},
			},
		},
		colors: ['#fabf7f', '#c099f8'],
		fill: {
			type: 'gradient',
			gradient: {
				shade: 'dark',
				gradientToColors: ['#fabf7f', '#c099f8'],
				shadeIntensity: 1,
				type: 'horizontal',
				opacityFrom: 0,
				opacityTo: 0.3,
				stops: [0, 100, 100, 100],
			},
		},
		legend: {
			show: false,
		},
		markers: {
			size: 2,
			colors: ['#fabf7f', '#c099f8'],
			strokeColors: '#c099f8',
			strokeWidth: 1,
			hover: {
				size: 3,
			},
		},
		yaxis: {
			min: 0,
			title: {
				style: {
					colors: ['white'],
					fontFamily: 'roboto',
				},
			},
		},
	}
}

/**
 * The default time string to display when no time-to-response insights are available.
 *
 * @type {String}
 */
const DEFAULT_TIME_STRING = '-'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ApexChart,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the average response time.
		 *
		 * @return {String}
		 */
		averageResponseTime() {
			if (!this.responseTimes || !this.responseTimes.avg_response) {
				return DEFAULT_TIME_STRING
			}

			return this.parseTimeToText(
				this.responseTimes.avg_response.split(':')
			)
		},

		/**
		 * Get the maximum response time.
		 *
		 * @return {String}
		 */
		maxResponseTime() {
			if (!this.responseTimes || !this.responseTimes.max_response) {
				return DEFAULT_TIME_STRING
			}

			return this.parseTimeToText(
				this.responseTimes.max_response.split(':')
			)
		},

		/**
		 * Get the minimum response time.
		 *
		 * @return {String}
		 */
		minResponseTime() {
			if (!this.responseTimes || !this.responseTimes.min_response) {
				return DEFAULT_TIME_STRING
			}

			return this.parseTimeToText(
				this.responseTimes.min_response.split(':')
			)
		},

		/**
		 * Get the average response time non urgent.
		 *
		 * @return {String}
		 */
		averageResponseTimeNonUrgent() {
			if (
				!this.responseTimes ||
				!this.responseTimes.non_urgent ||
				!this.responseTimes.non_urgent.avg_response
			) {
				return DEFAULT_TIME_STRING
			}

			return this.parseTimeToText(
				this.responseTimes.non_urgent.avg_response.split(':')
			)
		},

		/**
		 * Get the maximum response time.
		 *
		 * @return {String}
		 */
		maxResponseTimeNonUrgent() {
			if (
				!this.responseTimes ||
				!this.responseTimes.non_urgent ||
				!this.responseTimes.non_urgent.max_response
			) {
				return DEFAULT_TIME_STRING
			}

			return this.parseTimeToText(
				this.responseTimes.non_urgent.max_response.split(':')
			)
		},

		/**
		 * Get the minimum response time.
		 *
		 * @return {String}
		 */
		minResponseTimeNonUrgent() {
			if (
				!this.responseTimes ||
				!this.responseTimes.non_urgent ||
				!this.responseTimes.non_urgent.min_response
			) {
				return DEFAULT_TIME_STRING
			}

			return this.parseTimeToText(
				this.responseTimes.non_urgent.min_response.split(':')
			)
		},

		/**
		 * Get the chart's options.
		 *
		 * @return {Object}
		 */
		options() {
			const options = getDefaultChartOptions()

			options.xaxis.tickAmount = this.daily.length

			options.xaxis.categories = this.daily.map((day, index) => {
				const date = day.date.split('-')
				if (this.daily.length < 20) return `${date[1]}/${date[2]}`
				if (this.daily.length < 40)
					return index % 2 === 0 ||
						index === 0 ||
						index === this.daily.length - 1
						? `${date[1]}/${date[2]}`
						: ''
				return index % 3 === 0 ||
					index === 0 ||
					index === this.daily.length - 1
					? `${date[1]}/${date[2]}`
					: ''
			})

			return options
		},

		/**
		 * Get the chart's data to display.
		 *
		 * @return {Array}
		 */
		series() {
			return [
				{
					name: 'Urgent calls',
					data: this.daily.map(day => day.urgent_count),
				},
				{
					name: 'Non-Urgent calls',
					data: this.daily.map(day => day.non_urgent_count),
				},
			]
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Parse the given time to text.
		 *
		 * @param {Array} items
		 * @return {String}
		 */
		parseTimeToText([days, hours, minutes, seconds]) {
			days = parseInt(days)
			hours = parseInt(hours)
			minutes = parseInt(minutes)
			seconds = parseInt(seconds)

			if (seconds !== 0 && minutes === 0 && hours === 0 && days === 0) {
				return `${seconds}s`
			}

			const pieces = []

			if (minutes > 0) {
				pieces.push(`${minutes}m`)
			}

			if (hours > 0) {
				pieces.unshift(`${hours}h`)
			}

			if (days > 0) {
				pieces.unshift(`${days}d`)
			}

			return pieces.join(' ')
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'DailyChart',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The daily information to be displayed.
		 *
		 * @type {Array}
		 */
		daily: {
			type: Array,
			required: true,
		},

		/**
		 * The time to respond to be displayed.
		 *
		 * @type {Object}
		 */
		responseTimes: {
			type: Object,
			default() {
				return {
					avg_response: DEFAULT_TIME_STRING,
					max_response: DEFAULT_TIME_STRING,
					min_response: DEFAULT_TIME_STRING,
				}
			},
		},
	},
}
</script>
