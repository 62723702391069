<template>
	<div class="dashboard">
		<div class="dashboard-header">
			<div class="dashboard-header__left">
				<h1 class="dashboard-title">
					Call Insights: {{ name }}
					<h3 class="dashboard-title-subtext">
						Date and time shown in timezone: {{ partnerTimezone }}
					</h3>
				</h1>
			</div>
			<div class="dashboard-header__right">
				<div class="dashboard-filter-report">
					<button
						v-if="isPartnerAdmin"
						class="report-Button"
						@click="openReportsPanel"
					>
						Reports
					</button>

					<inbound-reports
						:show="panels.inboundReports"
						@close="panels.inboundReports = false"
					/>
				</div>
				<div class="dashboard-filter">
					<div class="flex flex-row align-items-center w-100">
						<div class="w-full relative">
							<button
								type="button"
								class="dashboard-filter-input-icon"
								@click.prevent="toggleDatePicker"
							>
								<font-awesome-icon
									:icon="['fal', 'calendar-alt']"
								/>
							</button>
							<flat-pickr
								id="dates"
								ref="dateFilter"
								v-model="dates"
								:config="configs.date"
								type="text"
								name="start"
								class="form-input dashboard-filter-input m-0"
								placeholder="Last 30 days"
								@on-close="onFilterClose"
							/>
						</div>
						<button
							type="button"
							class="btn btn-sm dashboard-filter-btn"
							aria-label="Filter reports"
							data-tooltip="Filter By Date Range"
							data-position="left"
							@click.prevent="onDatePickerSubmit"
						>
							<div class="btn-ripple"></div>
							<font-awesome-icon
								class="btn-icon"
								:icon="['fal', 'filter']"
							/>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="dashboard-insights">
			<div class="dashboard-card dashboard-card-flush">
				<div v-if="loading" class="dashboard-card-loader">
					<font-awesome-icon
						spin
						fixed-width
						size="3x"
						:icon="['far', 'spinner-third']"
					/>
					<h3 class="dashboard-card-loader-text">
						Loading Call Insights...
					</h3>
				</div>
				<daily-chart
					v-else
					:daily="daily"
					:response-times="timeToRespond"
				/>
			</div>
			<div class="dashboard-card-row">
				<div v-if="loading" class="dashboard-card">
					<div class="dashboard-card-loader">
						<font-awesome-icon
							spin
							fixed-width
							size="3x"
							:icon="['far', 'spinner-third']"
						/>
					</div>
				</div>
				<weekly-chart v-else :daily="daily" :title="dates" />
				<div v-if="loading" class="dashboard-card">
					<div class="dashboard-card-loader">
						<font-awesome-icon
							spin
							fixed-width
							size="3x"
							:icon="['far', 'spinner-third']"
						/>
					</div>
				</div>
				<hourly-chart v-else :hourly="hourly" :title="dates" />
			</div>
			<div class="dashboard-card-row">
				<div v-if="loading" class="dashboard-card">
					<div class="dashboard-card-loader">
						<font-awesome-icon
							spin
							fixed-width
							size="3x"
							:icon="['far', 'spinner-third']"
						/>
					</div>
				</div>
				<type-chart v-else :types="callTypes" :title="dates" />
				<div v-if="loading" class="dashboard-card">
					<div class="dashboard-card-loader">
						<font-awesome-icon
							spin
							fixed-width
							size="3x"
							:icon="['far', 'spinner-third']"
						/>
					</div>
				</div>
				<provider-chart v-else :providers="providers" :title="dates" />
			</div>
			<div class="dashboard-card-row">
				<div v-if="loading" class="dashboard-card-loader">
					<font-awesome-icon
						spin
						fixed-width
						size="3x"
						:icon="['far', 'spinner-third']"
					/>
				</div>
				<completed-chart v-else :completed="completed" :title="dates" />
				<div v-if="loading" class="dashboard-card">
					<div class="dashboard-card-loader">
						<font-awesome-icon
							spin
							fixed-width
							size="3x"
							:icon="['far', 'spinner-third']"
						/>
					</div>
				</div>
				<fallback-chart v-else :fallbacks="fallbacks" :title="dates" />
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import 'flatpickr/dist/flatpickr.css'
import FlatPickr from 'vue-flatpickr-component'
import TypeChart from '@/components/Charts/TypeChart.vue'
import DailyChart from '@/components/Charts/DailyChart.vue'
import HourlyChart from '@/components/Charts/HourlyChart.vue'
import WeeklyChart from '@/components/Charts/WeeklyChart.vue'
import FallbackChart from '@/components/Charts/FallbackChart.vue'
import ProviderChart from '@/components/Charts/ProviderChart.vue'
import CompletedChart from '@/components/Charts/CompletedChart.vue'
import InboundReports from '@/components/InboundReports.vue'
import _get from 'lodash/get'

/**
 *  Constant representing the timestamp format the API uses.
 *  @type {String}
 */
const API_FORMAT = 'ddd, DD MMM YYYY HH:mm:ss [GMT]'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		CompletedChart,
		DailyChart,
		FlatPickr,
		FallbackChart,
		HourlyChart,
		ProviderChart,
		TypeChart,
		WeeklyChart,
		InboundReports,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if there is any date filter.
		 *
		 * @return {Boolean}
		 */
		hasFilter() {
			return this.start && this.end
		},

		/**
		 * Determine if the user has the partner admin role or global admin
		 *
		 * @return {Boolean}
		 */
		isPartnerAdmin() {
			const roles = _get(this.$store.state, 'auth.user.roles', [])
			const activePartnerId = _get(
				this.$store.getters.partner,
				'id',
				null
			)

			const isAdminRole = roles.some(role => role.name === 'admin')

			if (isAdminRole) {
				return true
			}

			const isPartnerAdminRole = roles.some(
				role =>
					role.name === 'partner-admin' &&
					role.is_partner_role === true &&
					role.partner_id === activePartnerId
			)

			return isPartnerAdminRole
		},

		/**
		 * Get the partner's name.
		 *
		 * @return {String}
		 */
		name() {
			return this.partner?.name || ''
		},

		partnerTimezone() {
			return this.partner.timezone || 'America/New_York'
		},

		...mapGetters({
			partner: 'partners/active',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		getStartDate() {
			const date = this.start
				? moment(this.start)
				: moment().subtract(30, 'days')

			const formattedDate = date
				.tz(this.partnerTimezone, true)
				.startOf('day')
				.utc()
				.format(API_FORMAT)

			return formattedDate
		},

		getEndDate() {
			const baseDate = this.end
				? moment(this.end)
				: moment().subtract(1, 'days')

			const endMoment = baseDate
				.tz(this.partnerTimezone, true)
				.endOf('day')
				.utc()

			const now = moment()
				.tz(this.partnerTimezone, true)
				.endOf('day')
				.utc()

			const formattedDate = endMoment.isAfter(now)
				? now.format(API_FORMAT)
				: endMoment.format(API_FORMAT)

			return formattedDate
		},

		/**
		 * Get all of the insight requests.
		 *
		 * @return {Promise}
		 */
		getInsights() {
			return Promise.all([
				this.fetchDailyInsights(),
				this.fetchHourlyInsights(),
				this.fetchTypeInsights(),
				this.fetchCompletedByInsights(),
				this.fetchProvidersInsights(),
				this.fetchFallbacksInsights(),
				this.fetchToRespondInsights(),
			])
		},

		/**
		 * Fetch call completed insights.
		 *
		 * @return {Promise}
		 */
		fetchCompletedByInsights() {
			const startDate = this.getStartDate()
			const endDate = this.getEndDate()
			return this.$api
				.partners()
				.insights()
				.start(startDate === 'Invalid date' ? null : startDate)
				.end(endDate === 'Invalid date' ? null : endDate)
				.completed(this.partner.id)
		},

		/**
		 * Fetch daily call insights.
		 *
		 * @return {Promise}
		 */
		fetchDailyInsights() {
			const startDate = this.getStartDate()
			const endDate = this.getEndDate()
			return this.$api
				.partners()
				.insights()
				.start(startDate === 'Invalid date' ? null : startDate)
				.end(endDate === 'Invalid date' ? null : endDate)
				.daily(this.partner.id)
		},

		/**
		 * Fetch all the call insights.
		 *
		 * @return {void}
		 */
		async fetchData() {
			this.loading = true

			try {
				const [
					daily,
					hourly,
					types,
					completed,
					providers,
					fallbacks,
					timeToRespond,
				] = await this.getInsights()

				this.daily = daily.get('data', [])

				this.hourly = hourly.get('data', [])

				this.callTypes = types.get('data', [])

				this.completed = completed.get('data', [])

				this.providers = providers.get('data', [])

				this.fallbacks = fallbacks.get('data', [])

				this.timeToRespond = timeToRespond.get('data', {})
			} catch (e) {
				let message = 'Something went wrong, please try again later.'

				if (e.hasErrors()) {
					message =
						e.getFirstError('starts_at') ||
						e.getFirstError('ends_at') ||
						message
				}

				this.$alert.error(message)
			}

			this.loading = false
		},

		/**
		 * Fetch fallbacks by provider insights.
		 *
		 * @return {Promise}
		 */
		fetchFallbacksInsights() {
			const startDate = this.getStartDate()
			const endDate = this.getEndDate()
			return this.$api
				.partners()
				.insights()
				.start(startDate === 'Invalid date' ? null : startDate)
				.end(endDate === 'Invalid date' ? null : endDate)
				.fallback(this.partner.id)
		},

		/**
		 * Fetch hourly call insights.
		 *
		 * @return {Promise}
		 */
		fetchHourlyInsights() {
			const startDate = this.getStartDate()
			const endDate = this.getEndDate()
			return this.$api
				.partners()
				.insights()
				.start(startDate === 'Invalid date' ? null : startDate)
				.end(endDate === 'Invalid date' ? null : endDate)
				.hourly(this.partner.id)
		},

		/**
		 * Fetch providers call insights.
		 *
		 * @return {Promise}
		 */
		fetchProvidersInsights() {
			const startDate = this.getStartDate()
			const endDate = this.getEndDate()
			return this.$api
				.partners()
				.insights()
				.start(startDate === 'Invalid date' ? null : startDate)
				.end(endDate === 'Invalid date' ? null : endDate)
				.provider(this.partner.id)
		},

		/**
		 * Fetch time to respond insights.
		 *
		 * @return {Promise}
		 */
		fetchToRespondInsights() {
			const startDate = this.getStartDate()
			const endDate = this.getEndDate()
			return this.$api
				.partners()
				.insights()
				.start(startDate === 'Invalid date' ? null : startDate)
				.end(endDate === 'Invalid date' ? null : endDate)
				.timeToRespond(this.partner.id)
		},

		/**
		 * Fetch hourly call insights.
		 *
		 * @return {Promise}
		 */
		fetchTypeInsights() {
			const startDate = this.getStartDate()
			const endDate = this.getEndDate()
			return this.$api
				.partners()
				.insights()
				.start(startDate === 'Invalid date' ? null : startDate)
				.end(endDate === 'Invalid date' ? null : endDate)
				.type(this.partner.id)
		},

		/**
		 * Handle the date picker filter submit being clicked.
		 *
		 * @return {void}
		 */
		onDatePickerSubmit() {
			if (this.start && this.end) {
				this.fetchData()
			}
		},

		/**
		 * Handle the on close FlatPicker event.
		 *
		 * @param {Array} dates
		 * @return {void}
		 */
		onFilterClose(dates) {
			const format = 'YYYY-MM-DD'

			if (dates.length < 2) {
				this.end = null
				this.start = null

				return
			}

			this.start = moment(dates[0]).format(format)
			this.end = moment(dates[1]).format(format)
		},

		/**
		 * Toggle the date picker to open/close.
		 *
		 * @return {void}
		 */
		toggleDatePicker() {
			if (this.$refs.dateFilter && this.$refs.dateFilter.fp) {
				this.$refs.dateFilter.fp.toggle()
			}
		},

		openReportsPanel() {
			this.panels.inboundReports = true
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Dashboard',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route(to, from) {
			if (to.params.partner !== from.params.partner) {
				this.fetchData()
			}
		},
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	mounted() {
		this.fetchData()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		const yesterday = new Date().setDate(new Date().getDate())

		return {
			callTypes: [],
			completed: [],
			configs: {
				date: {
					allowInput: true,
					altInput: true,
					altFormat: 'm/d/Y',
					dateFormat: 'n-j-Y',
					mode: 'range',
					maxDate: yesterday,
					wrap: true,
				},
			},
			panels: {
				inboundReports: false,
			},
			daily: [],
			dates: null,
			end: null,
			fallbacks: [],
			hourly: [],
			loading: true,
			providers: [],
			start: null,
			timeToRespond: {},
		}
	},
}
</script>
